<template>
    <div class="row">
        <div class="col-12">
            <div id="page-title">
                <h1>{{title}}</h1>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: 'page-title',
    props: {
        title: {
            type: String,
            default: ''
        }
    }
}
</script>

<style lang="scss" scoped>
#page-title {
    margin-bottom: 20px;
}
</style>