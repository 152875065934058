<template>
    <slide-x-left-transition @after-leave="afterCollapseToggle" @after-enter="afterCollapseToggle">
        <div id="side-navbar" v-show="!$store.state.sidebarCollapsed">
            <div id="top-header-logo">
                <router-link :to="{name: 'dashboard'}" class="navbar-brand mr-0 d-block">
                    <img src="@/assets/images/transparent-light-savernake-logo.png" />
                </router-link>
            </div>
            <div class="scroll-container">
                <!-- <div id="account-selector-label">Account</div> -->
                <!-- <select id="account-selector" v-model="activeAccount" @change="onActiveAccountChange"><optgroup label="FX Accounts"><option value="New Track Record" data-currency="GBP">FX Track Record</option><option value="jefferies.fx.tolerance" data-currency="USD">Gain Tolerance</option><option value="gain.fx.gbp.1" data-currency="GBP">Gain FX GBP 1</option></optgroup><optgroup label="Futures Accounts"><option value="Futures Track Record" data-currency="USD">Futures Track Record</option><option value="cme.futures.demo.tolerance" data-currency="USD">CME Futures Tolerance</option><option value="adm.9179426a" data-currency="USD" selected="">ADM Futures Holding Account</option><option value="adm.91794353" data-currency="USD">ADM Futures 1</option><option value="adm.91794266" data-currency="USD">ADM Futures 2</option><option value="hft.tolerance" data-currency="USD">HFT Tolerance</option><option value="adm.91794375" data-currency="USD">ADM HFT</option></optgroup></select> -->
                <select id="account-selector" v-model="activeAccount" @change="onActiveAccountChange" style="margin-top: 20px;">
                    <optgroup label="FX Accounts" v-if="getFxAccounts().length > 0">
                        <option v-for="account in getFxAccounts()" :key="account.name+(account.maintenance?' (maintenance)':'')" :value="account.name" :data-currency="account.currency">{{account.displayName+(account.maintenance?' (maintenance)':'')}}</option>
                    </optgroup>
                    <optgroup label="Futures Accounts" v-if="getFuturesAccounts().length > 0">
                        <option v-for="account in getFuturesAccounts()" :key="account.name+(account.maintenance?' (maintenance)':'')" :value="account.name" :data-currency="account.currency">{{account.displayName+(account.maintenance?' (maintenance)':'')}}</option>
                    </optgroup>
                    <optgroup label="HFT Accounts" v-if="getHftAccounts().length > 0">
                        <option v-for="account in getHftAccounts()" :key="account.name" :value="account.name" :data-currency="account.currency">{{account.displayName+(account.maintenance?' (maintenance)':'')}}</option>
                    </optgroup>
                </select>
                <perfect-scrollbar>
                    <sidebar-menu showOneChild :menu="getMenu()"></sidebar-menu>
                </perfect-scrollbar>
            </div>
        </div>
    </slide-x-left-transition>
</template>

<script>
import { PerfectScrollbar } from 'vue2-perfect-scrollbar';
import { SlideXLeftTransition } from 'vue2-transitions';
import { SidebarMenu } from '@/components/vendor/vue-sidebar-menu';

export default {
    name: 'side-navbar',
    components: {
        PerfectScrollbar,
        SlideXLeftTransition,
        SidebarMenu
    },
    computed: {
        activeAccount: {
            get() {
                return this.$store.state.activeAccount;
            },
            set(value) {
                this.$store.commit('updateActiveAccount', value);
            }
        }
    },
    methods: {
        onActiveAccountChange(event) {
            this.$store.commit('updateActiveCurrency', event.target.options[event.target.selectedIndex].getAttribute('data-currency'));
            this.$ws.close();
        },
        getMenu() {
            let pages = this.$store.state.auth.user.allowedPages;
            return [
                // {
                //     title: 'Main Navigation',
                //     header: true,
                // },
                {
                    title: 'Dashboard',
                    icon: '',
                    href: '/dashboard',
                    hidden: pages.indexOf('dashboard') === -1
                },
                {
                    title: 'Performance',
                    icon: '',
                    child: [
                        {
                            title: 'Account Summary',
                            href: '/performance/account-summary',
                            hidden: pages.indexOf('account-summary') === -1
                        },
                        {
                            title: 'Performance Statistics',
                            href: '/performance/performance-statistics',
                            hidden: pages.indexOf('performance/performance-statistics') === -1
                        },
                        {
                            title: 'Breakdowns',
                            href: '/performance/breakdowns',
                            hidden: pages.indexOf('performance/breakdowns') === -1
                        },
                        {
                            title: 'BreakdownWinRates',
                            href: '/performance/breakdown-win-rates',
                            hidden: pages.indexOf('performance/breakdown-win-rates') === -1
                        },
                        {
                            title: 'Hourly Returns',
                            href: '/performance/hourly-returns',
                            hidden: pages.indexOf('performance/hourly-returns') === -1
                        },
                        {
                            title: 'Live vs Optimiser',
                            href: '/performance/live-vs-replay',
                            hidden: pages.indexOf('trades/live-vs-replay') === -1
                        },
                        {
                            title: 'Tolerance',
                            href: '/performance/tolerance',
                            hidden: pages.indexOf('trades/tolerance') === -1
                        },
                        {
                            title: 'Execution Tolerance',
                            href: '/performance/execution-tolerance',
                            hidden: pages.indexOf('trades/execution-tolerance') === -1
                        },
                        {
                            title: 'Missed Trades',
                            href: '/performance/missed-trades',
                            hidden: pages.indexOf('trades/missing-orders') === -1
                        },
                        {
                            title: 'In-Sample Data',
                            href: '/performance/return-and-win-rate',
                            hidden: pages.indexOf('performance/return-and-win-rate') === -1
                        },
                        {
                            title: 'Random Sample',
                            href: '/performance/random-sample',
                            hidden: pages.indexOf('performance/random-sample') === -1
                        },
                        {
                            title: 'Traded Minutes',
                            href: '/performance/traded-minutes',
                            hidden: pages.indexOf('performance/traded-minutes') === -1
                        },
                        
                    ],
                    hidden: pages.indexOf('account-summary') === -1 && pages.indexOf('performance/performance-statistics') === -1
                        && pages.indexOf('performance/breakdowns') === -1 && pages.indexOf('trades/live-vs-replay') === -1
                        && pages.indexOf('trades/tolerance') === -1 && pages.indexOf('trades/execution-tolerance') === -1
                        && pages.indexOf('trades/missing-orders') === -1 && pages.indexOf('performance/hourly-returns') === -1
                        && pages.indexOf('performance/performance-statistics') === -1
                        && pages.indexOf('performance/trade-period-stats' === -1)
                        && pages.indexOf('performance/fees-breakdown' === -1)
                },
                {
                    title: 'Risk',
                    icon: '',
                    child: [
                        {
                            title: 'Leverages',
                            href: '/risk/leverages',
                            hidden: pages.indexOf('month-end/leverages') === -1
                        }
                    ],
                    hidden: pages.indexOf('month-end/leverages') === -1
                },
                {
                    title: 'Operations',
                    icon: '',
                    child: [
                        {
                            title: 'Trades',
                            href: '/operations/trades',
                            hidden: pages.indexOf('trades/trades') === -1
                        },
                        {
                            title: 'Reconciliation',
                            href: '/operations/reconciliation',
                            hidden: pages.indexOf('trades/reconciliation') === -1
                        },
                        {
                            title: 'Executions',
                            href: '/operations/executions',
                            hidden: pages.indexOf('trades/executions') === -1
                        },
                        {
                            title: 'Reason Summary',
                            href: '/operations/reason-summary',
                            hidden: pages.indexOf('summary/reason') === -1
                        },
                        {
                            title: 'Volume Summary',
                            href: '/operations/volume-summary',
                            hidden: pages.indexOf('trades/volume') === -1
                        },
                        {
                            title: 'Platform Speed',
                            href: '/operations/platform-speed',
                            hidden: pages.indexOf('operations/platform-speed') === -1
                        },
                        {
                            title: 'Audit',
                            href: '/operations/audit',
                            hidden: pages.indexOf('operations/audit') === -1
                        },
                        {
                            title: 'Platform Timing',
                            href: '/operations/platform-timing',
                            hidden: pages.indexOf('operations/platform-timing') === -1
                        },
                        {
                            title: 'Cache Stats',
                            href: '/operations/cached-stats',
                            hidden: pages.indexOf('operations/cached-stats') === -1
                        },
                        {
                            title: 'Trade Predictions',
                            href: '/operations/trade-prediction',
                            hidden: pages.indexOf('operations/trade-prediction') === -1
                        },
                        {
                            title: 'Optimiser Progress',
                            href: '/operations/optimiser-progress',
                            hidden: pages.indexOf('operations/optimiser-progress') === -1
                        },
                        {
                            title: 'Platform Speed Report',
                            href: '/operations/platform-speed-report',
                            hidden: pages.indexOf('operations/platform-speed-report') === -1
                        }
                    ],
                    hidden: pages.indexOf('trades/trades') === -1 && pages.indexOf('trades/reconciliation') === -1 && pages.indexOf('trades/executions') === -1
                        && pages.indexOf('summary/reason') === -1 && pages.indexOf('trades/volume') === -1 && pages.indexOf('operations/platform-speed') === -1
                        && pages.indexOf('operations/system-health') === -1 && pages.indexOf('operations/platform-speed-report') === -1
                },
                {
                    title: 'System Health',
                    icon: '',
                    href: '/system-health',
                    hidden: pages.indexOf('system-health') === -1
                },
                {
                    title: 'Live Chart',
                    icon: '',
                    href: '/live-chart',
                    hidden: pages.indexOf('live-chart') === -1
                },
                {
                    title: 'Administration',
                    icon: '',
                    child: [
                        {
                            title: 'Maintenance',
                            href: '/administration/maintenance',
                            hidden: !this.$store.state.auth.user.internal
                        },
                        {
                            title: 'Manage Users',
                            href: '/administration/manageusers',
                            hidden: !this.$store.state.auth.user.internal
                        },
                        {
                            title: 'Charts',
                            href: '/administration/all-charts',
                            hidden: true
                        },
                        {
                            title: 'Report Creation',
                            href: '/administration/report-creation',
                            hidden: !this.$store.state.auth.user.internal
                        }
                    ],
                    hidden: !this.$store.state.auth.user.internal
                }
            ];
        },
        afterCollapseToggle(event) {
            window.dispatchEvent(new Event('resize'));
        },
        getFxAccounts() {
            let accounts = [];
            for(let account of this.$store.state.auth.user.allowedAccounts) {
                // if(this.$store.state.auth.user.id !== 1 && account.name.indexOf('tolerance') !== -1) {
                //     continue;
                // }

                if(this.$store.getters.isFxAccount(null, account)) {
                    accounts.push(account);
                }
            }
            return accounts;
        },
        getFuturesAccounts() {
            let accounts = [];
            for(let account of this.$store.state.auth.user.allowedAccounts) {
                // if(this.$store.state.auth.user.id !== 1 && account.name.indexOf('tolerance') !== -1) {
                //     continue;
                // }

                if(this.$store.getters.isFuturesAccount(null, account)) {
                    accounts.push(account);
                }
            }
            return accounts;
        },
        getHftAccounts() {
            let accounts = [];
            for(let account of this.$store.state.auth.user.allowedAccounts) {
                // if(this.$store.state.auth.user.id !== 1 && account.name.indexOf('tolerance') !== -1) {
                //     continue;
                // }

                if(this.$store.getters.isHftAccount(null, account)) {
                    accounts.push(account);
                }
            }
            return accounts;
        }
    }
}
</script>

<style lang="scss">
#side-navbar {
    width: $side-navbar-width;
    position: fixed;
    top: 0;
    left: 0;
    bottom: 0;
    background: $side-navbar-bg;
    z-index: 1000;

    #top-header-logo {
        width: $side-navbar-width;
        text-align: center;

        img {
            width: 150px;
            margin: auto;
            vertical-align: middle;
        }
    }

    .scroll-container {
        position: relative;
        height: calc(100vh - #{$top-header-height});

        #account-selector-label {
            color: rgba(255, 255, 255, 0.7);
            border-bottom: 1px solid rgba(255, 255, 255, 0.13);
            margin: 0 30px;
            font-size: 14px;
            font-weight: 600;
            padding: 15px 0;
        }

        #account-selector {
            font-family: inherit;
            width: 100%;
            background-color: #000730;
            color: #fff;
            border: 0;
            padding: 15px 26px;
            font-size: 14px;
            cursor: pointer;
            outline: none;
        }

        .ps {
            height: 100%;
        }
    }
}

@media screen and (max-width: 830px) {
    #side-navbar {
        display: none;
    }
}

#app.sidebar-not-collapsed #side-navbar {
    display: block;
}

#app.sidebar-collapsed #side-navbar {
    display: none;
}
</style>