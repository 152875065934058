<template>
    <div class="accounts-restarting-alerts-container">
        <b-alert variant="info" v-for="account in accounts" :key="account" show>
            Account {{account}} is restarting
        </b-alert>
    </div>
</template>

<script>
export default {
    name: 'accounts-restarting-alerts',
    data() {
        return {
            hasSetupListeners: false,
            accounts: []
        };
    },
    mounted() {
        this.requests();
    },
    destroyed() {
        this.$ws.off('reconnected', this.requests);
        this.$ws.off('restartaccountstatus', this.onRestartAccountsStatus);
        this.$ws.off('restartaccount', this.onRestartAccountsStatus);
    },
    methods: {
        requests() {
            if(!this.hasSetupListeners) {
                this.$ws.on('reconnected', this.requests);
                this.$ws.on('restartaccountstatus', this.onRestartAccountsStatus);
                this.$ws.on('restartaccount', this.onRestartAccountsStatus);
                this.hasSetupListeners = true;
            }

            this.$ws.send({
                accountName: this.$store.state.activeAccount,
                request: 'RestartAccountStatus',
                args: {}
            });
        },
        onRestartAccountsStatus(event) {
            this.accounts = [];
            
            for(let accountName in event.response) {
                if(event.response[accountName]) {
                    let account = this.$store.getters.getAccount(accountName);

                    if(account !== null) {
                        this.accounts.push(account.displayName);
                    }
                } else {
                    let account = this.$store.getters.getAccount(accountName);

                    if(account !== null) {
                        let index = this.$store.state.restartedAccounts.indexOf(accountName);
                        if(index !== -1) {
                            this.$store.commit('removeRestartedAccount', index);

                            // this.$notify({
                            //     group: 'primary',
                            //     text: `Account ${account.displayName} has finished restarting.`,
                            //     type: 'info'
                            // });
                        }
                    }
                }
            }
        }
    }
}
</script>