<template>
    <div id="main-content">
        <page-title :title="title" v-if="showTitle"></page-title>
        <div class="row" v-if="$store.state.maintenance.enabled">
            <div class="col-12">
                <template v-if="$store.state.maintenance.reason">
                    <b-alert show variant="danger">Dashboard is currently under maintenance for "{{$store.state.maintenance.reason}}".</b-alert>
                </template>
                <template v-else>
                    <b-alert show variant="danger">Dashboard is currently under maintenance.</b-alert>
                </template>
            </div>
        </div>
        <div class="row" v-if="$store.state.auth.user.internal">
            <div class="col-12">
                <accounts-restarting-alerts></accounts-restarting-alerts>
            </div>
        </div>
        <slot></slot>
    </div>
</template>

<script>
import PageTitle from '@/components/PageTitle';
import AccountsRestartingAlerts from '@/components/AccountsRestartingAlerts';

export default {
    components: {
        PageTitle,
        AccountsRestartingAlerts
    },
    props: {
        showTitle: {
            type: Boolean,
            default: true
        },
        title: {
            type: String,
            default: ''
        }
    }
}
</script>

<style lang="scss" scoped>
#main-content {
    margin-top: $top-header-height;
    margin-left: $side-navbar-width;
    padding: 1.5rem 1.7rem;
    width: calc(100% - #{$side-navbar-width});
    transition: all .5s ease-in-out;
}

#app.sidebar-collapsed #main-content {
    margin-left: 0;
    width: 100%;
}

#app.sidebar-not-collapsed #main-content {
    margin-left: $side-navbar-width;
    width: calc(100% - #{$side-navbar-width});
}

@media screen and (max-width: 830px) {
    #main-content {
        margin-left: 0 !important;
        width: 100% !important;
    }
}

@media screen and (max-width: 450px) {
    #main-content {
        margin-top: $top-header-height * 2;
    }
}
</style>