<template>
    <div id="top-header" class="navbar d-flex flex-row p-0">
        <div class="navbar-menu-wrapper d-flex align-items-center p-0">
            <ul class="navbar-nav">
                <li class="nav-item">
                    <a href="#" @click.prevent="onSidebarCollapse" class="nav-link sidebar-collapse-toggle d-flex justify-content-center align-items-center">
                        <i class="mdi mdi-menu"></i>
                    </a>
                </li>
            </ul>
            <ul class="navbar-nav ml-auto">
                <li class="nav-item">
                    <b-dropdown :text="`${$store.state.auth.user.firstName} ${$store.state.auth.user.lastName}`" class="profile-nav-dropdown" variant="light">
                        <b-dropdown-item @click.prevent="onLogoutClicked">Logout</b-dropdown-item>
                    </b-dropdown>
                </li>
            </ul>
        </div>
    </div>
</template>

<script>
import { logout } from '@/lib/Helpers';

export default {
    name: 'top-header',
    methods: {
        onSidebarCollapse(event) {
            this.$store.commit('updateSidebarCollapse');
        },
        onLogoutClicked() {
            logout();
        }
    }
}
</script>

<style lang="scss" scoped>
#top-header {
    min-height: $top-header-height;
    position: fixed;
    top: 0;
    right: 0;
    background: $top-header-bg;
    z-index: 900;
    transition: all 0.5s ease-in-out;

    .navbar-menu-wrapper {
        padding: 0 15px;
        height: $top-header-height;
        box-shadow: $top-header-box-shadow;
        flex-grow: 1;

        .navbar-nav {
            .nav-item {
                .nav-link {
                    transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out, -webkit-box-shadow 0.15s ease-in-out;

                    &:hover {
                        background: #f1f1f1;
                    }

                    &.sidebar-collapse-toggle {
                        height: $top-header-height;
                        padding: 0 20px;

                        i {
                            font-size: 1.25rem;
                        }
                    }
                }
            }
        }
    }
}

@media screen and (max-width: 830px) {
    #top-header {
        display: none;
    }
}

#app.sidebar-not-collapsed #top-header {
    left: $side-navbar-width;
}

#app.sidebar-collapsed #top-header {
    left: 0;
}

@media screen and (max-width: 450px) {
    #top-header {
        left: 0;
    }
}
</style>

<style lang="scss">
.profile-nav-dropdown {
    &.show .dropdown-toggle {
        background: #f1f1f1 !important;
    }

    .dropdown-toggle {
        height: $top-header-height !important;
        background: transparent !important;
        border: none;

        &:after {
            vertical-align: .05em;
        }
    }
}
</style>